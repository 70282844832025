// main:style.sass

.text-box {
  max-width: 600px;
  padding: 60px 15px;
  margin: 0 auto;
  &:first-child {
    padding-top: 30px;
    @media screen and (min-width: $break-sm) {
      padding-top: 60px; } }
  @media screen and (min-width: $break-sm) {
    max-width: 860px;
    font-size: 1em; }
  // @media screen and (min-width: $break-lg)
  //   padding: 80px 15px
  h1, h2, h3, h4, h5, h6, b {
    font-weight: 800;
    font-family: 'Nunito Sans', sans-serif;
 }    // text-transform: uppercase

  h1 {
    font-size: 2.8em; }
  h2 {
    font-size: 2.1em; }
  h4 {
    font-size: 1.1em; }
  h6 {
    font-size: 1em; }
  p {
    line-height: 1.4em; }
  b {
    letter-spacing: -0.02em;
    font-size: 1em; } }

.about-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  span {
    width: 25%;
    display: flex;
    justify-content: center;
    img {
      max-width: 35px;
      max-height: 35px; } }
  @media screen and (min-width: $break-xs) {
    span {
      display: inline;
      // img
      //   max-width: 50px
      //   max-height: 50px
      //   &:hover
 } }      //     text-shadow: $shadow
  @media screen and (min-width: $break-sm) {
    justify-content: flex-start;
    span {
      width: 10%; } } }





.outter-mt {
  margin-top: 120px; }


.social-grid {
  max-width: 800px;
  margin: 0 auto;
  span {
    position: relative;
    width: 33.33%;
    padding-bottom: 33.33%;
    float: left;
    outline: 1px solid red; } }
