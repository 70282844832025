// main: ./style.sass

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(255,255,255,0.98);
  >div {
    @include vendor-prefix(transition, all 0.3s ease-in-out);
    height: 50px;
    border-bottom: 3px solid $black1;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0;
    align-items: center;
    @media screen and (min-width:$break-sm) {
      margin: 0 1%;
      height: 60px; }
    @media screen and (min-width:$break-xl) {
      margin: 0 0.7%; } } }

.nav-title {
  width: 40px;
  margin: 0 0 0 10px;
  h4 {
    padding-top: 4px;
    text-transform: uppercase; } }

.nav-logo {
  img {
    @include vendor-prefix(transition, all 0.3s ease-in-out);
    width: 50px;
    height: 50px;
    vertical-align: bottom;
    &:hover {
      box-shadow: $shadow; } } }


.nav-ham {
  width: 50px;
  display: flex;
  justify-content: center; }


.hamburger {
  // position: relative
  // height: 30px
  width: 30px;
  // height: 22px
  z-index: 50;
  margin: 0 auto;
  display: block;
  align-self: center;
  div {
    @include vendor-prefix(transition, all 0.15s ease-in-out);
    display: block;
    width: 30px;
    height: 3px;
    background-color: black;
    &:first-child {
      margin-bottom: 6px; }
    &:nth-child(2) {
      margin-bottom: 6px; } }
  &:hover div {
   &:nth-child(1) {
    width: 20px;
    @include vendor-prefix(transform, rotate(-45deg) translateY(0px) translateX(-4px)); }
   &:nth-child(2) {
    width: 35px; }
   &:nth-child(3) {
    width: 20px;
    @include vendor-prefix(transform, rotate(45deg) translateY(0px) translateX(-4px)); } } }



// *
//   outline: 1px solid red

.headroom--unpinned {
  nav>div {
    height: 30px;
    @media screen and (min-width: $break-sm) {
      height: 40px; } }
  .nav-logo {
    img {
      @include vendor-prefix(transform, translateY(-120%));
      opacity: 0; } } }


.headroom {
  * {
    z-index: 50; } }

.headroom--pinned, .headroom--pinned.headroom--scrolled {
  .nav-logo {
    img {
      @include vendor-prefix(transform, translateY(0));
      opacity: 1; } } }
