// main:style.sass

// @keyframes name-of-animation
//   0%
//     transform: rotate(0deg)
//   100%
//     transform: rotate(360deg)

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-ms-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }


@include keyframes(fade) {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@include keyframes(topToBot) {
  0% {
    opacity: 0;
    transform: translateY(-30%); }

  // 80%
  //   opacity: 0.1
  100% {
    opacity: 1;
    transform:  translateY(0); } }


@include keyframes(botToTop) {
  0% {
    opacity: 0;
    transform: translateY(10%); }

  // 80%
  //   opacity: 0.1
  100% {
    opacity: 1;
    transform:  translateY(0); } }

@include keyframes(botToTopWork) {
  0% {
    opacity: 0;
    transform: translateY(0.5%); }

  // 80%
  //   opacity: 0.1
  100% {
    opacity: 1;
    transform:  translateY(0); } }

.topToBot {
  animation: topToBot 1 0.3s ease-in-out; }

.fade {
  animation: fade 1 0.5s ease-in; }

.fade.photos, .fade.work {
  animation: fade 1 1s ease-in-out; }


.fade.botToTop {
  animation: botToTop 1 0.5s ease-in-out; }

.botToTopWork {
  animation: botToTopWork 1 0.5s ease-in-out; }
