//

//global style
$break-xs: 576px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;
$break-xl: 1600px;

$black0: #080808;
$black1: #171717;

$shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$shadow-hover: 0 10px 20px rgba(0,0,0,0.3), 0 6px 6px rgba(0,0,0,0.34);

@mixin vendor-prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument}; }

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  color: $black1;
  font-size: 16px;
  // transition: all 0.2s ease
  @media screen and (min-width: $break-sm) {
    font-size: 1.125em; } }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Josefin Sans';
  font-weight: 400; }

a {
  color: $black1;
  text-decoration: none;
  &:hover {
    color: $black0; } }

a.hover-underline {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background-color: $black1;
      visibility: hidden;
      @include vendor-prefix(transform, scaleX(0));
      @include vendor-prefix(transition, all 0.2s ease-in-out); }
    &:hover:before {
      visibility: visible;
      @include vendor_prefix(transform, scaleX(1)); } }

a.hover-underline.live-site-button {
    &:before {
      width: 100%;
      height: 3px;
      bottom: -5px;
      left: 0; } }



.fullscreen-border {
  border: 3px solid $black1;
  box-shadow: $shadow;
  position: fixed;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: -1;
  @media screen and (min-width: $break-sm) {
    border: 3px solid $black1;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px; }
  @media screen and (max-height: 567px) {
    display: none; } }


.container {
  margin: 0 auto;
  padding: 0 32px;
  @media screen and (min-width: $break-md) {
    max-width: 970px; }
  @media screen and (min-width: $break-lg) {
    max-width: 1170px; }
  @media screen and (min-width: $break-xl) {
    max-width: 1540px; } }



.text {
  font-family: 'Lato', sans-serif; }



.text-center {
  text-align: center; }

#logo {
  z-index: 999; }


// @import 'work'
// @import 'error'
@import 'about';
// @import 'photo'
@import 'index';
@import 'nav';
@import 'comTransition';
