// main: ./style.sass


//Index.js style
.index-outter-wrap {
  // width: 100vw
  // height: 100vh
  // display: flex
  min-height: 550px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  div:not(.fullscreen-border):not(.container) {
    padding: 20px 0;
    @media screen and (min-height: $break-sm) {
      padding: 40px 0; } } }

.index-intro {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  @media screen and (min-width: $break-sm) {
    max-width: 220px; }
  p {
    line-height: 2.1em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    font-family: 'Josefin Sans', sans-serif; } }

.index-logo {
  display: flex;
  justify-content: center;
  img {
    width: 100px;
    height: 100px;
    box-shadow: $shadow; } }

.index-menu {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Josefin Sans', sans-serif;
  span {
    text-align: center;
    padding: 10px 0; }
  a {
    display: inline-block;
    position: relative;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0px;
    &:after {
      content: '';
      height: 100vh;
      width: 100vw;
      background-size: cover;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      z-index: -10;
      transition: all 0.2s ease-out;
      filter: blur(50px) grayscale(100%);
      background-position: center; }
    &:hover:after {
      opacity: 0.15;
      filter: blur(0) grayscale(100%); } }

  a.nav-about:after {
    background-image: url('../images/bg-about.jpg'); }
  a.nav-works:after {
    background-image: url('../images/bg-work.jpg'); }
  a.nav-photos:after {
    background-image: url('../images/bg-photo.jpg'); } }

.index-social {
  display: flex;
  justify-content: center;
  img {
    width: 25px;
    margin: 0 10px;
    vertical-align: middle; } }
